/*==================== Variables ====================*/
/* Constant */
const DURATION = {
  FAST: 150,
  FASTER: 200,
  STD: 300,
  NOR: 400,
  SLOWER: 500,
  SLOW: 600
};

/*==================== Initialize ====================*/
/**
 * 【程式進入點】
 * 項目名稱: Includes 載入器
 *
 * 說明:
 *    監聽所有 includes 的載入、並作同步處理，
 *    下載完成後進入 listenImagesLoading，
 *    監聽完成即進入 this.callback。
 *
 * 必填項目: includesList
 */
includesLoader({
  // filesRootPath: "./includes/",
  // filesExtension: ".html",
  callback: mainFunc,
  includesList: [
    {
      target: ".page-header",
      // rootPath: "./includes/",
      fileName: "_page-header"
      // extension: ".html",
      // callback: function() {}
    },
    {
      target: ".page-footer",
      fileName: "_page-footer"
    }
  ]
});

/*==================================================*\
        Main Function
\*==================================================*/
function mainFunc() {
  	/* window 事件: Resize & scroll */
  	windowEvents();

	// $("button").lightbox(() => {
	// 	$("._slick").slick();
	// });

	  /***** Start Coding Here *****/
	  


  	// Header & Menu
	var page = $('body').data('page');
	$('.menu a.' + page).addClass('active');
	
	$('.btn-menu').on('click', function () {
		$(this).toggleClass('_open');
		$('.menu').fadeToggle();
	})
	$('.btn-menu._open').on('click', function () {
		$(this).toggleClass('_open');
		$('.menu').fadeOut();
	})


	// 首頁動畫
	// var i = 0;                     //  set your counter to 1
	// function myLoop () {           //  create a loop function
	// 	setTimeout(function () {    //  call a 3s setTimeout when the loop is called
	// 		// alert('hello');          //  your code here
	// 		i++;                     //  increment the counter
	// 		if (i < 12) {            //  if the counter < 10, call the loop function
	// 			$('.flower img').attr('src','./images/flower/flower-'+ i +'.png')
	// 			myLoop();             //  ..  again which will trigger another 
				
	// 		}                        //  ..  setTimeout()
	// 	}, 120)
	// }
	// myLoop();

	// 診所
	// if ( $('body').hasClass('clinic-page') ){
	// 	$('.area-nav button').on('click', clinicSwitch);
	
	// 	function clinicSwitch() {
	// 		var idx = $(this).index();
	// 		$(this).addClass('active').siblings('button').removeClass('active');
	// 		$('.clinic-box').hide().eq(idx).show();
	// 	}
	// }
	if ( $('body').hasClass('index-page') ){
		$('.kv-slider').on('init', function (event, slick, direction) {

			// check to see if there are one or less slides
			if (!($('.kv-slider .slider').length > 1)) {
	  
				 // remove arrows
				 $('.slick-dots').hide();
	  
			}
	  
	  });

		$('.kv-slider').slick({
			dots: true,
			// arrows: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
  			autoplaySpeed: 3000
		});
	}

	// 雜誌相關
	if ( $('body').hasClass('newsPage-page') ){
		$("._slide").slick({
			dots: true,
			arrows: true,
			infinite: true,
			speed: 300,
			slidesToShow: 2,
			slidesToScroll: 2,
			responsive: [
			  {
				 breakpoint: 1024,
				 settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				 }
			  }
			]
		});
	}



  	/* 收掉 .page-loading */
	transitionThenRemove({
		dom: $(".page-loading"),
		duration: DURATION.STD,
		callback: () => {
			console.log("%cBuild Completed!", logSafeStyle);
		}
	});
}

/*==================================================*\
        window Events
\*==================================================*/
let isFirstTime = true;
function windowEvents() {
	// Window Resize
	$win.on("resize", _resize).resize();
	// Window Scroll
	$win.on("scroll", _scroll).scroll();
}

/*========== Window Resize ==========*/
function _resize() {
	getSize();

	// 斷點偵測
	detectiveBreakpoint([
		{
		minimum: -1,
			mq: function() {}
		},
		{
		phoneMini: 320,
			mq: function() {}
		},
		{
		phone: 400,
			mq: function() {}
		},
		{
		phoneWide: 480,
			mq: function() {}
		},
		{
		phablet: 560,
			mq: function() {}
		},
		{
		tabletSmall: 640,
			mq: function() {}
		},
		{
		tablet: 768,
			mq: function() {}
		},
		{
		tabletWide: 1024,
			mq: function() {
				$('.btn-menu._open').removeClass('_open');
				$('.menu').hide();
			}
		},
		{
		notebook: 1366,
			mq: function() {}
		},
		{
		desktop: 1680,
			mq: function() {}
		}
	]);
}

function getSize() {
	winW = $win.width();
	winH = $win.height();
}

/*========== Window Scroll ==========*/
let nowPos;
// alert(winH)
function _scroll() {
	getPos();
	var winTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
	var winH = $('html').height(),
		winW = $('html').width();
	// var nowpos = nowPos.y;
	
	console.log("winTop : "+winTop)
	// console.log("nowPos.y : "+nowpos)
	if ( winW > 1024 ){
		if ( $('body').hasClass('index-page') ) {
			var treatmentTop = $('.ind-treatment').offset().top - winH;
			// console.log("treatmentTop : "+treatmentTop)
			// alert('asd')
			if ( winTop > treatmentTop + 200 ) {
				indStep.play();
			}
		}
		
		if ( $('body').hasClass('propaganda-page') ){
			var propaganda1Top = $('.propaganda-box .propaganda_1').offset().top - winH,
				propaganda2Top = $('.propaganda-box .propaganda_2').offset().top - winH,
				propaganda3Top = $('.propaganda-box .propaganda_3').offset().top - winH;
			if ( winTop > propaganda1Top + 300 ) {
				propaganda1In.play();
			}
			if ( winTop > propaganda2Top + 300 ) {
				propaganda2In.play();
			}
			if ( winTop > propaganda3Top + 300 ) {
				propaganda3In.play();
			}
		}
	}
}

function getPos() {
	nowPos = {
		x: $doc.scrollLeft(),
		y: $doc.scrollTop()
	};
}

/*==================================================*\
        Library
\*==================================================*/
/* 斷點偵測 (for window resize) */
let preBP = { minimum: -1 };
function detectiveBreakpoint(breakpoint) {
	let nowBP = breakpoint[0];

	$.each(breakpoint, (idx, obj) => {
		const objName = Object.getOwnPropertyNames(obj)[0];
		const val = obj[objName];

		if (winW > val && val > nowBP[Object.getOwnPropertyNames(nowBP)[0]]) {
			nowBP = obj;
		}
	});

	if (
		Object.getOwnPropertyNames(nowBP)[0] ===
		Object.getOwnPropertyNames(preBP)[0]
	) {
		return false;
	}

	// 執行 Media Query
	mediaQuery(nowBP);
}

// 執行 Media Query
function mediaQuery(nowBP) {
	const nowBPName = Object.getOwnPropertyNames(nowBP)[0];
	console.log(`Breakpoint {${nowBPName}: ${nowBP[nowBPName]}}`);

	// 執行該斷點 Media Query
	if (!nowBP.hasOwnProperty("mq")) {
		throw new Error(`此斷點(↑)尚未設定 Media Query 之屬性 "mq"(function)`);
	} else if (typeof nowBP.mq !== "function") {
		throw new Error(`此中斷點之 Media Query 型別並非 "function`);
	} else {
		nowBP.mq();
	}

	preBP = nowBP;
}



let scrollData = {
	kv: {
		height: 0,
		track: false
	},
	precursor: {
		height: 0,
		track: false
	},
	video: {
		height: 0,
		track: false
	}
},
scrollVar,
kvManPos,
kvLogoPos
scrollData.kv.height = $('header').outerHeight() + $('.kv').outerHeight()
scrollData.precursor.height = scrollData.kv.height + $('.precursor').outerHeight()

$(window).resize(function() {
scrollData.kv.height = $('header').outerHeight() + $('.kv').outerHeight()
scrollData.precursor.height =
	scrollData.kv.height + $('.precursor').outerHeight()
})
// scroll, domAnimation, tracking
$(window).scroll(function() {
	scrollVar = $(window).scrollTop()

	kvManPos = parseFloat(0 - (20 * scrollVar) / scrollData.kv.height) + '%'
	$('.kv-item').css('bottom', kvManPos)

	kvLogoPos = parseFloat(28 + (33 * scrollVar) / scrollData.kv.height) + '%'
	$('.logo-box').css('top', kvLogoPos)

})


var indStep = new TimelineMax({ paused: true});
indStep
.staggerTo('.treatment li', .5, { css:{transform:" translate(0px)",opacity: 1}}, .15, '-=0')

var propaganda1In = new TimelineMax({ paused: true});
propaganda1In
.to('.propaganda_1 > div', .5, { css:{transform:" translate(0px)",opacity: 1}}, '-=0')

var propaganda2In = new TimelineMax({ paused: true});
propaganda2In
.to('.propaganda_2 > div', .5, { css:{transform:" translate(0px)",opacity: 1}}, '-=0')

var propaganda3In = new TimelineMax({ paused: true});
propaganda3In
.to('.propaganda_3 > div', .5, { css:{transform:" translate(0px)",opacity: 1}}, '-=0')